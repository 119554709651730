/**
 * replaces an array element, returning a new array, leaving the original unchanged.
 * @param array The array to replace an element in
 * @param element The element to set
 * @param index The index to set the element at. Must be an integer >= 0
 */
export function replaceArrayElement<T>(array: ReadonlyArray<T>, element: T, index: number) {
  const copy = [...array]
  copy[index] = element
  return copy
}

/**
 * remove an array element, returning a new array, leaving the original unchanged.
 * @param array The array to remove an element from
 * @param index The index to set the element at. If negative, no removal will occur
 */
export function removeArrayElement<T>(array: ReadonlyArray<T>, index: number) {
  if (index < 0) {
    return array
  }
  const copy = [...array]
  copy.splice(index, 1)
  return copy
}

/**
 * Returns the provided value exactly as it was presented, with no copying or mutation
 * This is used for provided a generalized no-op in higher order function composition
 * @param t Any value
 */
export function identity<T>(t: T) {
  return t
}

/**
 * Returns a function that always returns the provided element when called
 * @param t Any value to be returned via a thunk
 */
export function thunk<T>(t: T) {
  return () => t
}

/**
 * An ordering for sort functions, with -1 meaning Less Than, 0 being Equal, and 1 being Greater Than
 * Basically just a formalization of the underlying JS sort functions
 */
export type Ordering = -1 | 0 | 1
/**
 * A type defintions for functions which can safely compare and order values
 */
export type Comparator<T> = (t1: T, t2: T) => Ordering

/**
 * Inverts the ordering provided by a comparator, so that a sorting order can be inverted without
 * a second array traversal
 * @param c The original comparator function to invert the resultant Ordering of
 */
export function invertComparator<T>(c: Comparator<T>): Comparator<T> {
  return (t1: T, t2: T) => (0 - c(t1, t2)) as Ordering
}

/**
 * Allows arrays to be sorted by a more abstract means of comparison through functions that can yield a comparable object
 * @param array An array of anything
 * @param projection A projection from the array element type into some other type that can be compared
 * @param comparator A comparison for two different members of the array, after projection
 */
export function sortProjectedArray<S, T>(
  array: ReadonlyArray<S>,
  projection: (s: S) => T,
  comparator: Comparator<T>
): ReadonlyArray<S> {
  const copied = [...array]
  copied.sort((s1, s2) => comparator(projection(s1), projection(s2)))
  return copied
}

/**
 * Sort an array safely, yielding a copy, sorted according to an arbitrary function
 * @param array An array of anything
 * @param comparator a function providing a means to compare elements of that array
 */
export function sortArray<T>(array: ReadonlyArray<T>, comparator: Comparator<T>): ReadonlyArray<T> {
  return sortProjectedArray(array, identity, comparator)
}

/**
 * Provides a browser cross compatible means of performing flatMap on arrays
 */
export function flatMap<S, T>(
  array: ReadonlyArray<S>,
  mapping: (s: S) => ReadonlyArray<T>
): ReadonlyArray<T> {
  return array.reduce<ReadonlyArray<T>>((acc, e) => [...acc, ...mapping(e)], [])
}

/** Intersperse a collection with a given delimeter */
export function* intersperse<T>(array: ReadonlyArray<T>, delimeter: T) {
  let first = true
  for (const x of array) {
    if (!first) yield delimeter
    first = false
    yield x
  }
}

/**
 * Transform an array of entities into a hash.
 * Each key is associated with an entity.
 * Overwrite duplicate keys.
 *
 * @param array the array to transform
 * @param key the callback that generates the key,
 *            with the iterated entity and index as parameters
 */
export function keyBy<T>(
  array: ReadonlyArray<T>,
  key: (t: T, idx: number) => string
): { [key: string]: T } {
  return array.reduce((entities, entity, idx) => {
    entities[key(entity, idx)] = entity
    return entities
  }, {})
}

/**
 * Transform an array of entities into a hash.
 * Each key is associated with an array of entities.
 * The keys represent groups.
 *
 * @param array the array to transform
 * @param key the callback that generates the key,
 *            with the iterated entity and index as parameters
 */
export function groupBy<T>(
  array: ReadonlyArray<T>,
  key: (t: T, idx: number) => string
): { [key: string]: ReadonlyArray<T> } {
  return array.reduce((entities, entity, idx) => {
    const group = key(entity, idx)
    const grouped = entities[group] ? entities[group] : []
    entities[group] = [...grouped, entity]
    return entities
  }, {})
}

/**
 * Returns a copy of an array with copies
 * of the objects it holds.
 * @param array the array to copy
 */
export function makeImmutableArrayOfObjects<T>(array: ReadonlyArray<T>): ReadonlyArray<T> {
  return array.map(obj => ({ ...obj }))
}

/**
 * Returns the smallest entry in an array of objects according to given param
 * @param objectList An array of objects with keys that can be parsed to floats
 * @param param the key of the objects to compare between
 */
export function getSmallestEntryByParam<O extends { [key: string]: any }, K extends keyof O>(
  objectList: ReadonlyArray<O>,
  param: K
): O {
  return objectList.reduce(function (prev, curr) {
    return parseFloat(prev[param]) < parseFloat(curr[param]) ? prev : curr
  })
}

/**
 * Determines if an array has duplicate entries
 * @param array
 */
export function hasDuplicates(array: ReadonlyArray<string | number>): boolean {
  const valuesSoFar = Object.create(null)
  for (let i = 0; i < array.length; ++i) {
    const value = array[i]
    if (value in valuesSoFar) {
      return true
    }
    valuesSoFar[value] = true
  }
  return false
}

/**
 * Find the elements from the first array that are not in the second array.
 *
 * @param array1 first array
 * @param array2 second array
 */
export function difference<T>(
  array1: ReadonlyArray<T>,
  array2: ReadonlyArray<T>
): ReadonlyArray<T> {
  return array1.filter(element => !array2.includes(element))
}

/**
 * Find the elements that both arrays share in common.
 *
 * @param array1 first array
 * @param array2 second array
 */
export function intersection<T>(
  array1: ReadonlyArray<T>,
  array2: ReadonlyArray<T>
): ReadonlyArray<T> {
  return array1.filter(element => array2.includes(element))
}
